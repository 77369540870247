import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "image", "clone", "append" ]
  connect() {
    // add "loading to last image, when controller connects"
    // we will wait for the last image to appear in the viewport
    // and then we will append the clone
    this.lastPhoto = this.imageTargets[this.imageTargets.length - 1]
    this.lastPhoto.classList.add("loading")
  }

  onScroll() {
    console.log("scrolling")
    // check if mobile
    var hasTouchScreen = this.isMobile()
    // if not, fire
    if (!hasTouchScreen) {
      // You need to create the context, otherwise line26 will look for
      // addTheClone function in the IntersectionObserver context and not
      // in this controller
      const addtheClone = this.addTheClone()
      // this.cloneTarget.remove()
      let observer = new IntersectionObserver(function(entries) {
        // isIntersecting is true when element and viewport are overlapping
        // isIntersecting is false when element and viewport don't overlap
        if(entries[0].isIntersecting === true) {
          console.log('Element has just become visible in screen');
          //  Not 100% sure, but here "this" refers to the context of this function, I believe. Not the IntersectionObserver API
          addTheClone
        }
      }, { threshold: [0] });

      observer.observe(document.querySelector(".loading"));
    }
  }

  isMobile() {
    let hasTouchScreen = false;

    let UA = navigator.userAgent;
    hasTouchScreen = (
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA)
    );

    return hasTouchScreen;
  }

  addTheClone() {
    console.log("firing")
    this.lastPhoto.classList.remove("loading")
    this.appendTarget.insertAdjacentHTML("beforeend", this.cloneTarget.outerHTML);
    this.lastPhotoClone = this.imageTargets[this.imageTargets.length - 1]
    this.lastPhotoClone.classList.add("loading")
  }
}
