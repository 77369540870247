import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="collaborator"
export default class extends Controller {
  static targets = [ "add" ]

  connect() {
    console.log("hi from collaborators controller")
    console.log("append to:", this.addTarget)
  }

  addCollab() {
    const inputs = `
    <div class="d-flex w-100" data-collaborator-target="add">
      <div class="mb-3 string optional project_collaborators_position"><input class="form-control string optional" placeholder="position" type="text" name="project[collaborators][][position]" id="project_collaborators__position"></div>
      <div class="mb-3 string optional project_collaborators_name"><input class="form-control string optional" placeholder="name" type="text" name="project[collaborators][][name]" id="project_collaborators__name"></div>
    </div>
    `
    // this.addTarget.preventDefault()
    console.log("action works!")
    console.log(inputs)
    this.addTarget.insertAdjacentHTML("beforeend", inputs)
  }
}
