import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="submit"
export default class extends Controller {
  static targets = [ "input" ]

  connect() {
    console.log("submit controller")
  }

  rearrange(event) {
    event.preventDefault()
    console.log("event stopped")
    const inputs = this.inputTargets
    inputs.forEach((element, index) => {
      element.value = index
      event.target.submit()
    });
  }
}
