import { Controller } from "@hotwired/stimulus"
import Sortable from "sortablejs";

export default class extends Controller {
  static targets = [ "list", "li" ]

  connect() {
    const listItems = this.liTargets
    console.log(listItems)
    this.initSortable()
  }

  initSortable() {
    // these options are not really used right now. For some reason when I add an
    // onEnd callback as an option it does not fire.
    const options = {
      ghostClass: "ghost",
      animation: 150,
    };

    Sortable.create(this.listTarget, { onEnd: this.handleEnd });
  };

  handleEnd(){
    // console.log("list items in handle event:", this.liTargets)
    console.log(event);
    // this.liTargets.forEach(element => {
    //   console.log(element)
    // });
    // return all lis with id="update me",
    // iterate over them, and
    // increment the values values
    // console.log(`${event.oldIndex} moved to ${event.newIndex}`)
  }
}
